@font-face {
    font-family: "text2";
    src: url("Sk-Modernist-Regular.otf")  format("opentype");
    font-display: swap;
}

@font-face {
    font-family: 'text1';
    src: url('Bigilla-Bold.otf') format('opentype');
    font-display: swap;
}

* {
    box-sizing: border-box;
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    justify-content: center;
    margin: 0;
    background: blanchedalmond;
    font-family: 'text2';
    overflow-x: hidden;
    scroll-behavior: smooth;
}

html {
    height: 100%;
    overflow: hidden;
    width: 100%;
}

body {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
}

section {
    position: relative;
    padding: 5em 10%;
    background: #011627;
    color: #fff;
    text-align: center;
    box-shadow: 0 12px 17px 2px #888;
}

.col-2 {
    background-image: repeating-linear-gradient(36deg, rgba(255,255,255, 0.1), rgba(255,255,255, 0.1) 1px, transparent 0px, transparent 2px);

    .column {
        width: 49%;
    }
}


.column {
    display: inline-block;
    vertical-align: top;
}


section.color h2 {
    color: #fff;
}

.color {
    background: #3498db;
}


/*** Individual section styles and separators ***/


/* Common style for pseudo-elements */

section::after {
    position: absolute;
    content: '';
    pointer-events: none;
}


/* Triangles */
.ss-style-triangles::after {
    left: 50%;
    width: 60px;
    height: 60px;
    -webkit-transform: translateX(-50%) rotate(45deg);
    transform: translateX(-50%) rotate(45deg);
    box-shadow: 7px 7px 6px 0px #888;
}

.ss-style-triangles::after {
    bottom: -1.875rem;
    z-index: 10;
    background: inherit;
}



.toggle-button {
    position: absolute;
    top: .75rem;
    right: 1rem;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 20px;
    padding: unset !important;
    background-color: #011627;

    .bar {
        background-color: #fbfbfb;
        height: .5rem;
        width: 100%;
        border-radius: 10px;
    }
}

.arrow-indicator0,
.arrow-indicator1,
.arrow-indicator2,
.arrow-indicator5,
.arrow-indicator6 {
    text-align: center;
    transition: 1s;
}

.arrow-indicator0.active,
.arrow-indicator2.active,
.arrow-indicator1.active,
.arrow-indicator5.active,
.arrow-indicator6.active {
    transition: .5s;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.home-bar {
    width: 100%;
    display: flex;
    font: 'PT Serifs';
    justify-content: space-between;
    align-items: center;
    background-color: #011627;
    position: fixed;
    z-index: 999;
    padding: 1rem;

    .nith-title {
        color: #FFF;
        font-family: 'text1';
        left: 0;
        font-size: 3vh;
        visibility: hidden;
    }

    .navbar-links {
        padding: .3rem;
    }


    nav {
        a {
            font-family: 'text1';
            font-size: 2.5vh;
            padding: 1.3rem;
            color: #FDFFFC;
            right: 0;
            border: none;
            background: none;
            text-decoration: none
        }

        a:hover {
            background-color: #E71D36
        }
    }
}

.presentation {
    svg {
        scale: 197%;
        position: relative;
        bottom: 10px;
    }
}

.projects {
    display: flex;
    justify-content: center;
    margin: auto;
    width: 50%;
    padding: 7rem 2rem;

    p {
        text-align: center;
        width: 30rem;
    }
}

.edu-container,
.work-container {
    margin-top: 4rem;

    >div {
        box-shadow: 0px 12px 17px 2px #888;
        border-radius: 12px;
        padding: 1rem;
        margin-bottom: 2rem;
    }

    .lox,
    .hav,
    .hull,
    .wren,
    .made {
        background-color: #011627;
        color: white;
    }

    .wren-detail,
    .made-detail,
    .lox-detail,
    .hull-detail,
    .hav-detail {
        transition: max-height 1s ease-in-out;
        overflow: hidden;
        max-height: 0;
        margin-right: -1rem;
        margin-left: -1rem;
        padding-bottom: 0rem;
        margin-bottom: -1rem;
        background-color: rgb(255, 255, 255);
        border-radius: 0 0 12px 12px;
        color: black;
        overflow: scroll;
        font-size: 1.5vh;

        div {
            margin: 1rem;
            list-style: none;
            text-align: justify;
        }

        ul>li {
            list-style-type: none;
        }
    }

    .wren-detail.active,
    .made-detail.active,
    .lox-detail.active,
    .hull-detail.active,
    .hav-detail.active {
        overflow: auto;
        max-height: 1000px;
    }
}

.user-icon {
    >svg {
        transform: scale(1.5);
    }
}

.footer {
    color: #fff;
    background: #011627;
    padding: 2rem 5%;
    display: flex;
    flex-wrap: inherit;
    flex-direction: row;

    .footer-container {
        margin-left: auto;

        p {
            margin: auto;
            text-align: center;
            padding-bottom: 2rem;
        }

        >div {
            margin-bottom: 20px;
            width: 5rem;
            float: left;
            color: #fff;
            text-align: center;
        }
    }

}

#education {
    width: 50%;
    margin: auto;
    padding: 7rem 1rem 4rem;

    p {
        padding-top: 3rem;
        text-align: center;
        width: 30rem;
    }

    .user-icon {
        transform: scale(2.5);
        text-align: center;
    }
}

#work-experience {
    .edu-containers {
        width: 46%;
    }
}

.containers {
    width: 50%;
    margin: auto;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 4rem;
    padding-top: 7rem;

    p {
        width: 100%;
        font-size: 2.0vh;
        text-align: center;
    }

    .icon {
        transform: scale(2.5);
        text-align: center;
    }
}

.app {
    .image-container {
        display: flex !important;
        align-items: center;
        justify-content: center;
        background-color: #011627;

        img {
            width: 600px;
            height: 400px;
        }
    }
}

.divider {
    position: relative;
    margin-top: 45px;
    margin-bottom: 45px;
    height: 1px;
}

.div-transparent:before {
    content: "";
    position: absolute;
    top: 0;
    left: 5%;
    right: 5%;
    width: 90%;
    height: 1px;
    background-image: linear-gradient(to right, transparent, rgb(48, 49, 51), transparent);
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


.home-bar {
    .nith-title.active {
        font-family: 'text1';
        visibility: visible;
    }
}

.app>div:nth-child(2)>p:nth-child(1) {
    width: 451px;
    background: aliceblue;
    width: 58rem;
    text-align: center;
    font-family: 'Libre Baskerville', serif;
    left: 0;
    font-size: 4vh;
    padding: 1rem;
}

.me {
    display: flex;

    div {
        font-family: 'text1';
        font-size: 456.2%;
        margin: auto;
        width: 50%;
        padding: 0.625rem;
        text-align: initial;
        word-wrap: break-word;
    }

    .image-container {
        display: inline-block;
        position: relative;
        clip-path: circle(32% at 50% 50%);
    }
}

@media screen and (max-width: 800px) {
    #education, #work-experience, .containers {
      width: 75%;
    }

    #image-container {
        display: none !important;
    }

    .presentation {
        .svg {
            scale: 170%;
        }

        .div {
            font-size: 60%;
        }
    }

    #col-2 {
        padding: 6em 4em 3em 10%;
    }

    #name {
        font-size: 210%;
        width: unset;
    }

    #navbar-links {
        padding: 17px 4px 1rem;
        font-size: 20px;
    }

    #navbar-links.active {
        display: flex;
        align-content: strectch;
        flex-direction: column;
    }

    .footer .footer-container > div {
        width: 3rem;
        float: left;
        color: #fff;
        text-align: center;
        margin-bottom: 20px;
      }
}


@media (max-width: 1100px) {
    .toggle-button {
        display: flex;
    }

    .nith-title {
        text-indent: -99999px;
        line-height: 0;
    }

    .nith-title::after {
        content: 'Nith';
        text-indent: 0;
        display: block;
        line-height: initial;
    }

    .navbar-links {
        display: none;
        flex-direction: inherit;
        position: unset !important;
        right: unset !important;
    }

    .navbar-links.active {
        display: flex;
    }

    .home-bar {
        .nith-title.active {
            visibility: visible;
        }
    }

    .navbar {
        flex-direction: column;
    }
}