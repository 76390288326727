@font-face {
  font-family: text2;
  src: url("Sk-Modernist-Regular.d63c8fef.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: text1;
  src: url("Bigilla-Bold.9d4836d6.otf") format("opentype");
  font-display: swap;
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  scroll-behavior: smooth;
  background: #ffebcd;
  justify-content: center;
  margin: 0;
  padding: 0;
  font-family: text2;
  overflow-x: hidden;
}

html {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

body {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

section {
  color: #fff;
  text-align: center;
  background: #011627;
  padding: 5em 10%;
  position: relative;
  box-shadow: 0 12px 17px 2px #888;
}

.col-2 {
  background-image: repeating-linear-gradient(36deg, #ffffff1a, #ffffff1a 1px, #0000 0 2px);
}

.col-2 .column {
  width: 49%;
}

.column {
  vertical-align: top;
  display: inline-block;
}

section.color h2 {
  color: #fff;
}

.color {
  background: #3498db;
}

section:after {
  content: "";
  pointer-events: none;
  position: absolute;
}

.ss-style-triangles:after {
  width: 60px;
  height: 60px;
  z-index: 10;
  background: inherit;
  bottom: -1.875rem;
  left: 50%;
  transform: translateX(-50%)rotate(45deg);
  box-shadow: 7px 7px 6px #888;
}

.toggle-button {
  width: 30px;
  height: 20px;
  background-color: #011627;
  flex-direction: column;
  justify-content: space-between;
  display: none;
  position: absolute;
  top: .75rem;
  right: 1rem;
  padding: unset !important;
}

.toggle-button .bar {
  height: .5rem;
  width: 100%;
  background-color: #fbfbfb;
  border-radius: 10px;
}

.arrow-indicator0, .arrow-indicator1, .arrow-indicator2, .arrow-indicator5, .arrow-indicator6 {
  text-align: center;
  transition: all 1s;
}

.arrow-indicator0.active, .arrow-indicator2.active, .arrow-indicator1.active, .arrow-indicator5.active, .arrow-indicator6.active {
  transition: all .5s;
  transform: rotate(180deg);
}

.home-bar {
  width: 100%;
  font: "PT Serifs";
  z-index: 999;
  background-color: #011627;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  display: flex;
  position: fixed;
}

.home-bar .nith-title {
  color: #fff;
  visibility: hidden;
  font-family: text1;
  font-size: 3vh;
  left: 0;
}

.home-bar .navbar-links {
  padding: .3rem;
}

.home-bar nav a {
  color: #fdfffc;
  background: none;
  border: none;
  padding: 1.3rem;
  font-family: text1;
  font-size: 2.5vh;
  text-decoration: none;
  right: 0;
}

.home-bar nav a:hover {
  background-color: #e71d36;
}

.presentation svg {
  position: relative;
  bottom: 10px;
  scale: 197%;
}

.projects {
  width: 50%;
  justify-content: center;
  margin: auto;
  padding: 7rem 2rem;
  display: flex;
}

.projects p {
  text-align: center;
  width: 30rem;
}

.edu-container, .work-container {
  margin-top: 4rem;
}

.edu-container > div, .work-container > div {
  border-radius: 12px;
  margin-bottom: 2rem;
  padding: 1rem;
  box-shadow: 0 12px 17px 2px #888;
}

.edu-container .lox, .work-container .lox, .edu-container .hav, .work-container .hav, .edu-container .hull, .work-container .hull, .edu-container .wren, .work-container .wren, .edu-container .made, .work-container .made {
  color: #fff;
  background-color: #011627;
}

.edu-container .wren-detail, .work-container .wren-detail, .edu-container .made-detail, .work-container .made-detail, .edu-container .lox-detail, .work-container .lox-detail, .edu-container .hull-detail, .work-container .hull-detail, .edu-container .hav-detail, .work-container .hav-detail {
  max-height: 0;
  color: #000;
  background-color: #fff;
  border-radius: 0 0 12px 12px;
  margin-bottom: -1rem;
  margin-left: -1rem;
  margin-right: -1rem;
  padding-bottom: 0;
  font-size: 1.5vh;
  transition: max-height 1s ease-in-out;
  overflow: scroll;
}

.edu-container .wren-detail div, .work-container .wren-detail div, .edu-container .made-detail div, .work-container .made-detail div, .edu-container .lox-detail div, .work-container .lox-detail div, .edu-container .hull-detail div, .work-container .hull-detail div, .edu-container .hav-detail div, .work-container .hav-detail div {
  text-align: justify;
  margin: 1rem;
  list-style: none;
}

.edu-container .wren-detail ul > li, .work-container .wren-detail ul > li, .edu-container .made-detail ul > li, .work-container .made-detail ul > li, .edu-container .lox-detail ul > li, .work-container .lox-detail ul > li, .edu-container .hull-detail ul > li, .work-container .hull-detail ul > li, .edu-container .hav-detail ul > li, .work-container .hav-detail ul > li {
  list-style-type: none;
}

.edu-container .wren-detail.active, .work-container .wren-detail.active, .edu-container .made-detail.active, .work-container .made-detail.active, .edu-container .lox-detail.active, .work-container .lox-detail.active, .edu-container .hull-detail.active, .work-container .hull-detail.active, .edu-container .hav-detail.active, .work-container .hav-detail.active {
  max-height: 1000px;
  overflow: auto;
}

.user-icon > svg {
  transform: scale(1.5);
}

.footer {
  color: #fff;
  flex-wrap: inherit;
  background: #011627;
  flex-direction: row;
  padding: 2rem 5%;
  display: flex;
}

.footer .footer-container {
  margin-left: auto;
}

.footer .footer-container p {
  text-align: center;
  margin: auto;
  padding-bottom: 2rem;
}

.footer .footer-container > div {
  width: 5rem;
  float: left;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
}

#education {
  width: 50%;
  margin: auto;
  padding: 7rem 1rem 4rem;
}

#education p {
  text-align: center;
  width: 30rem;
  padding-top: 3rem;
}

#education .user-icon {
  text-align: center;
  transform: scale(2.5);
}

#work-experience .edu-containers {
  width: 46%;
}

.containers {
  width: 50%;
  margin: auto;
  padding: 7rem 1rem 4rem;
}

.containers p {
  width: 100%;
  text-align: center;
  font-size: 2vh;
}

.containers .icon {
  text-align: center;
  transform: scale(2.5);
}

.app .image-container {
  background-color: #011627;
  justify-content: center;
  align-items: center;
  display: flex !important;
}

.app .image-container img {
  width: 600px;
  height: 400px;
}

.divider {
  height: 1px;
  margin-top: 45px;
  margin-bottom: 45px;
  position: relative;
}

.div-transparent:before {
  content: "";
  width: 90%;
  height: 1px;
  background-image: linear-gradient(to right, #0000, #303133, #0000);
  position: absolute;
  top: 0;
  left: 5%;
  right: 5%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.home-bar .nith-title.active {
  visibility: visible;
  font-family: text1;
}

.app > div:nth-child(2) > p:nth-child(1) {
  width: 451px;
  width: 58rem;
  text-align: center;
  background: #f0f8ff;
  padding: 1rem;
  font-family: Libre Baskerville, serif;
  font-size: 4vh;
  left: 0;
}

.me {
  display: flex;
}

.me div {
  width: 50%;
  text-align: initial;
  word-wrap: break-word;
  margin: auto;
  padding: .625rem;
  font-family: text1;
  font-size: 456.2%;
}

.me .image-container {
  clip-path: circle(32%);
  display: inline-block;
  position: relative;
}

@media screen and (max-width: 800px) {
  #education, #work-experience, .containers {
    width: 75%;
  }

  #image-container {
    display: none !important;
  }

  .presentation .svg {
    scale: 170%;
  }

  .presentation .div {
    font-size: 60%;
  }

  #col-2 {
    padding: 6em 4em 3em 10%;
  }

  #name {
    width: unset;
    font-size: 210%;
  }

  #navbar-links {
    padding: 17px 4px 1rem;
    font-size: 20px;
  }

  #navbar-links.active {
    align-content: strectch;
    flex-direction: column;
    display: flex;
  }

  .footer .footer-container > div {
    width: 3rem;
    float: left;
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
  }
}

@media (max-width: 1100px) {
  .toggle-button {
    display: flex;
  }

  .nith-title {
    text-indent: -99999px;
    line-height: 0;
  }

  .nith-title:after {
    content: "Nith";
    text-indent: 0;
    line-height: initial;
    display: block;
  }

  .navbar-links {
    flex-direction: inherit;
    display: none;
    position: unset !important;
    right: unset !important;
  }

  .navbar-links.active {
    display: flex;
  }

  .home-bar .nith-title.active {
    visibility: visible;
  }

  .navbar {
    flex-direction: column;
  }
}

/*# sourceMappingURL=index.e5f96de2.css.map */
